import { css, Global } from '@emotion/core'
import { withTheme } from 'emotion-theming'
import * as React from 'react'

const HelperStyles = withTheme(() => (
  <Global
    styles={css`
      /* hiding for now until spanish pages are live */
      .inline-block {
        display: inline-block;
      }
    `}
  />
))

export default HelperStyles
