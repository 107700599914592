import { css, Global } from '@emotion/core'
import { withTheme } from 'emotion-theming'
import * as React from 'react'

const PackageCardStyles = withTheme(() => (
  <Global
    styles={css`
      .leshen-package-card {
        .title {
          text-transform: unset !important;
        }
      }
    `}
  />
))

export default PackageCardStyles
