import { withTheme } from 'emotion-theming'
import React from 'react'
import CtaBarStyles from './CtaBar.styles'
import FormStyles from './Form.styles'
import HelperStyles from './Helpers.styles'
import PackageCardStyles from './PackageCard.styles'
import TypographyStyles from './Typography.styles'

const GlobalStyles = withTheme(() => (
  <>
    <HelperStyles />
    <TypographyStyles />
    <FormStyles />
    <PackageCardStyles />
    <CtaBarStyles />
  </>
))

export default GlobalStyles
